<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <div class="row">
        <div class="col-lg-2 col-md-2 col-sm-12">
          <side-bar class="d-none d-md-block" />
        </div>
        <div class="col-md-10 col-lg-10 col-sm-12">
          <template>
            <div>
              <b-button
                class="btn btn-gradient-primary mb-1 mr-50 col-md-2.5"
                @click="$router.go(-1)"
              >
                <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
              </b-button>
              <b-row class="match-height">
                <b-col md="6">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-0">
                        {{ $t('details') }}
                      </h3>
                    </div>

                    <div class="p-2">
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('orderNumber') }} :
                            </template>

                            <p class="mt-50 font-weight-bolder">
                              {{items.itemnumber_text}}
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('status') }} :
                            </template>
                            <p
                              class="mt-50 mb-0 badge badge-light-primary"
                              v-if="items.status === 'waiting'"
                            >
                              {{ $t('pendingReview') }}
                            </p>
                            <p
                              class="mt-50 mb-0 badge badge-light-success"
                              v-if="items.status === 'success'"
                            >
                              {{ $t('operationCompleted') }}
                            </p>
                            <p
                              class="mt-50 mb-0 badge badge-light-secondary"
                              v-if="items.status === 'cancel'"
                            >
                              {{ $t('cancelled') }}
                            </p>  <small class="text-danger">{{ items.remark_admin ? `* ${items.remark_admin}`: '' }}</small>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('key-154') }} :
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              {{Commas(items.amount)}} ฿
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('transactionDate') }} :
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              {{items.file_date}}
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('transferEvidence') }} :
                            </template>
                            <div>
                              <img
                                v-if="items.fileimg"
                                :src="GetImg('refillslip',items.fileimg)"
                                alt="img"
                                width="100px"
                                class="rounded"
                                @click="showPicture(items.fileimg)"
                              >
                              <vue-image-lightbox-carousel
                                ref="lightbox"
                                :show="showLightbox"
                                :images="images"
                                :show-caption="false"
                                @close="closeBox()"
                              />
                              <!-- @/assets/images/anan-img/svg/doc_empty.svg -->
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>

                <b-col md="6">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-0">
                        {{ $t('key-155') }}
                      </h3>
                    </div>

                    <div class="p-2">
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('bank') }} :
                            </template>

                            <p class="mt-60 font-weight-bolder">
                              <img
                                :src="`/bankIcon/${items.accountbank.path_photo}`"
                                width="30px"
                                class="mr-50 rounded-sm"
                              >{{items.accountbank.bankname}} 
                              
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('accountName') }} :
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              {{items.accountbank.accName}}
                              <!-- {{ $t('PLMExpress') }} -->
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('accountNumber') }} :
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              {{items.accountbank.accNumber}}
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols-md="4"
                          >
                            <template v-slot:label>
                              {{ $t('branch') }} :
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              {{items.accountbank.branch}}
                              <!-- {{ $t('key-156') }} -->
                            </p>
                          </b-form-group>
                        </b-col>

                      </b-row>
                    </div>
                  </div>
                </b-col>

                <b-col md="12">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-0">
                        {{ $t('relatedAmountList') }}
                      </h3>
                    </div>

                    <div class="pl-2 pr-2">
                      <b-table
                        responsive="sm"
                        :fields="fieldsRelevant"
                        :items="Transations"
                        show-empty
                      >
                        <template #empty="">
                          <b-table-empty-slot>
                            <div class="text-center my-2">
                              <img
                                src="@/assets/images/anan-img/svg/doc_empty.svg"
                                alt="empty"
                                height="60px"
                              >
                              <p class="text-muted">
                                No Data
                              </p>
                            </div>
                          </b-table-empty-slot>
                        </template>

                        <template #cell(amount)="data">
                          <span :style="getAmountStyle(data.item.type)">
                            {{ getAmountPrefix(data.item.type) }}
                            {{ Commas(data.item.amount) }} ฿
                          </span>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BRow, BCol, BFormGroup, BButton, BTable,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import SideBar from '../component/SideBar.vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    SideBar,
    // ThisHeader,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      isActive: true,
      file: null,
      parcelCode: null,
      remark: null,
      Transaction: [],
      parcelList: [
        {
          value: '1',
          text: '1',
        },
        {
          value: '2',
          text: '2',
        },
        {
          value: '3',
          text: '3',
        },
      ],

      ID: this.$route.params.id,
      items: [],
      fileadmin: null,
      fileadminimg: null,
      Transations: [],
      images: [],
      showLightbox: false,
    }
  },
  computed: {
    fieldsRelevant() {
      return [
        {
          key: 'created_at',
          label: this.$t('transactionDate'),
          thStyle: { width: '20%' },
          formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'detail', label: this.$t('details'), thStyle: { width: '60%' } },
        {
          key: 'amount',
          label: this.$t('quantity'),
          thStyle: { width: '20%' },
          formatter: value => `${this.Commas(value.toFixed(2))} ฿`,
        },
      ]
    },
  },
  mounted(){  
    this.GetData()
    this.GetTransactions()
  },
  methods:{
    async GetData(){
      try{
        const params = {
          id: this.ID
        }
        const {data: res} = await this.$http.get('/Refill/RefillView', {params})
        this.items = res 
        // console.debug(res)
      }catch(err){
        console.log(err)
      }
    },
    async GetTransactions(){
      try{
        const params = {
          ref_id: this.ID
        }
        const {data: res} = await this.$http.get('/transactions/show/ref', {params})
        if(res.success){
          this.Transations = res.data
        }
      }catch(err){
        console.log(err)
      }
    },
    getAmountStyle(amount) {
      if (amount) {
        if (amount && [3, 4, 5, 6, 7, 13, 15, 16, 18, 10, 9].includes(amount)) {
          return { color: 'red' }
        }
        if (amount && [1,8, 11, 12, 17].includes(amount)) {
          return { color: 'green' }
        }
      }
      return { color: 'orange' }
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('refillslip', picture) }
      this.images.push(img)
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    getAmountPrefix(value) {
      if (value) {
        if (value && [3, 4, 5, 6, 7, 13, 15, 16, 18, 10, 9].includes(value)) {
          return '-'
        }
        if (value && [1,8, 11, 12, 17].includes(value)) {
          return '+'
        }
      }
      return ''
    },
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
